/*!
Template Name: turulav
Template URI: https://www.templatemonster.com/authors/gullatheme/
Author: GullaTheme.net
Author URI: https://www.templatemonster.com/authors/gullatheme/
Description: Description
Version: 1.0.0
Text Domain: turulav
Tags: community, couple, creative, dating, groups, love template, matching, members, online dating, profile, relationship, romance, social media, social network

*/
/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Normalize
# Typography
# Global CSS
# Header CSS
	*Style-1
	*Style-2
	*Mobile Header
# Page Header
# Banner
	*Style-1
	*Style-2
# Main
    *home + all common section
# Blog
	*Style-1
	*Blog Single
# Blog Widget
# Contact Us
#Footer
--------------------------------------------------------------*/








/* @@@@@@@@@@@@@  Extend Property CSS Writing Now  @@@@@@@@@@@@@@ */
%flex {
    display: flex;
    flex-wrap: wrap;
}

%rel {
    position: relative;
}

%abs {
    position: absolute;
    content: "";
}

%mp-0 {
    padding: 0;
    margin: 0;
}

%hidden {
    overflow: hidden;
}

%pointer {
    cursor: pointer;
}

%w-100 {
    width: 100%;
}

%h-100 {
    height: 100%;
}

%text-bold {
    font-weight: 700;
}

%text-regular {
    font-weight: 400;
}

%center {
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

%justify-center {
    justify-content: center;
}

%justify-between {
    justify-content: space-between;
}

%justify-start {
    justify-content: flex-start;
}

%align-items-center {
    align-items: center;
}




%d-block {
    display: block;
}

%d-inline-block {
    display: inline-block;
}

%border-radius-2 {
    border-radius: 2px;
}

/* @@@@@@@@@@@@@  Extend Property CSS Writing Now  @@@@@@@@@@@@@@ */

%text-clamp-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    @extend %hidden;
    text-overflow: ellipsis;
}

%text-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    @extend %hidden;
    text-overflow: ellipsis;
}

%text-clamp-1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    @extend %hidden;
    text-overflow: ellipsis;
}

/* @@@@@@@@@@@@@  Extend Property CSS Writing Now  @@@@@@@@@@@@@@ */
%white-color {
    color: $white-color;
}

%black-color {
    color: $black-color;
}

%bg-white {
    background-color: $white-color;
}

%bg-color {
    background-color: $body-color;
}

%title-color {
    color: $title-color;
}

%bg-title {
    background: $title-color;
}

%theme-color {
    color: $theme-color;
}

%bg-theme {
    background: $theme-color;
}

%bg-primary {
    background: $primary-color;
}

%border-color {
    color: $border-color;
}

%bg-border {
    background: $border-color;
}

%desc-color {
    color: $desc-color;
}

%bg-desc {
    background: $desc-color;
}

%ash-color {
    color: $ash-color;
}

%bg-ash {
    background: $ash-color;
}

%border-color {
    border-color: $border-color;
}

%border-color-theme {
    border-color: $theme-color;
}

%border-color-title {
    border-color: $title-color;
}

%text-center {
    text-align: center;
}

%text-right {
    text-align: right;
}

%list-none {
    list-style: none;
}

/* @@@@@@@@@@@@@  Extend Property Margin 10 - 30 CSS Writing Now  @@@@@@@@@@@@@@ */
%m-0 {
    margin: 0;
}

%m-30 {
    margin: 30px;
}

%mb-0 {
    margin-bottom: 0px;
}

%mb-10 {
    margin-bottom: 10px;
}

%mb-25 {
    margin-bottom: 25px;
}

%mt-10 {
    margin-top: 10px;
}

%ml-0 {
    margin-left: 0;
}

%ml-5 {
    margin-left: 5px;
}

%ml-10 {
    margin-left: 10px;
}

%mr-0 {
    margin-right: 0;
}

%mr-5 {
    margin-right: 5px;
}

%mr-10 {
    margin-right: 10px;
}

%mb-15 {
    margin-bottom: 15px;
}

%mt-0 {
    margin-top: 0;
}

%mt-15 {
    margin-top: 15px;
}

%mt-25 {
    margin-top: 25px;
}

%ml-15 {
    margin-left: 15px;
}

%mr-15 {
    margin-right: 15px;
}

%mb-20 {
    margin-bottom: 20px;
}

%mt-20 {
    margin-top: 20px;
}

%ml-20 {
    margin-left: 20px;
}

%mr-20 {
    margin-right: 20px;
}

%mb-0 {
    margin-bottom: 0px;
}
%mb-5 {
    margin-bottom: 5px;
}

%mb-30 {
    margin-bottom: 30px;
}

%mb-30-none {
    margin-bottom: -30px;
}

%mt-30 {
    margin-top: 30px;
}

%ml-30 {
    margin-left: 30px;
}

%mr-30 {
    margin-right: 30px;
}

%my-0 {
    margin-top: 0;
    margin-bottom: 0;
}

%my-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

%my-15 {
    margin-top: 15px;
    margin-bottom: 15px;
}

%my-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

%my-25 {
    margin-top: 25px;
    margin-bottom: 25px;
}

%my-30 {
    margin-top: 30px;
    margin-bottom: 30px;
}

%mx-0 {
    margin-left: 0;
    margin-right: 0;
}

%mx-10 {
    margin-left: 10px;
    margin-right: 10px;
}

%mx-15 {
    margin-left: 15px;
    margin-right: 15px;
}

%mx-20 {
    margin-left: 20px;
    margin-right: 20px;
}

%mx-25 {
    margin-left: 25px;
    margin-right: 25px;
}

%mx-30 {
    margin-left: 30px;
    margin-right: 30px;
}

/* @@@@@@@@@@@@@  Extend Property padding 10 - 30 CSS Writing Now  @@@@@@@@@@@@@@ */
%p-0 {
    padding: 0;
}

%p-5 {
    padding: 5px;
}

%p-10 {
    padding: 10px;
}

%p-15 {
    padding: 15px;
}

%p-20 {
    padding: 20px;
}
%p-20-10{
    padding: 20px 10px;
}

%p-30 {
    padding: 30px;
}

%pb-0 {
    padding-bottom: 0;
}

%pb-5 {
    padding-bottom: 5px;
}

%pb-10 {
    padding-bottom: 10px;
}

%pt-0 {
    padding-top: 0;
}

%pt-5 {
    padding-top: 5px;
}

%pt-10 {
    padding-top: 10px;
}

%pl-0 {
    padding-left: 0;
}

%pl-5 {
    padding-left: 5px;
}

%pl-10 {
    padding-left: 10px;
}

%pl-20 {
    padding-left: 20px;
}

%pr-5 {
    padding-right: 5px;
}

%pr-10 {
    padding-right: 10px;
}

%pb-15 {
    padding-bottom: 15px;
}

%pt-15 {
    padding-top: 15px;
}

%pl-15 {
    padding-left: 15px;
}

%pr-15 {
    padding-right: 15px;
}

%pb-20 {
    padding-bottom: 20px;
}

%pt-20 {
    padding-top: 20px;
}

%pl-20 {
    padding-left: 20px;
}

%pr-20 {
    padding-right: 20px;
}

%pb-30 {
    padding-bottom: 30px;
}

%pt-30 {
    padding-top: 30px;
}

%pl-30 {
    padding-left: 30px;
}

%pr-30 {
    padding-right: 30px;
}